import * as Yup from 'yup'

const ValidationSchemaSportData = {
    sportData: Yup.object({
        attendeeNew: Yup.object().when(['childAthletics', 'attendee'], {
            is: (childAthletics: boolean, attendee: 'new' | 'member') => childAthletics && attendee === 'new',
            then: Yup.object({
                firstName: Yup.string().required(' '),
                lastName: Yup.string().required(' '),
                dateOfBirth: Yup.string().required(' '),
                street: Yup.string().required(' '),
                streetNumber: Yup.string().required(' '),
                zip: Yup.number()
                    .test('length', '5 stellige PLZ', (value) => value?.toString().length === 5)
                    .required(' '),
                city: Yup.string().required(' '),
                email: Yup.string().email().required(' '),
            }),
        }),
        attendeeMember: Yup.object().when(['childAthletics', 'attendee'], {
            is: (childAthletics: boolean, attendee: 'new' | 'member') => childAthletics && attendee === 'member',
            then: Yup.object({
                memberId: Yup.string().required(' '),
                firstName: Yup.string().required(' '),
                lastName: Yup.string().required(' '),
                dateOfBirth: Yup.string().required(' '),
            }),
        }),
        // Sports section with the at-least-one validation
        baseball: Yup.boolean(),
        basketball: Yup.boolean(),
        soccer: Yup.boolean(),
        handball: Yup.boolean(),
        judo: Yup.boolean(),
        jujutsu: Yup.boolean(),
        karate: Yup.boolean(),
        athleticsAddons: Yup.object().shape({
            athletics: Yup.boolean(),
            walking: Yup.boolean(),
        }),
        chess: Yup.boolean(),
        swimming: Yup.boolean(),
        danceAddon_ballett: Yup.boolean(),
        danceAddon_bellydance: Yup.boolean(),
        danceAddon_breakdance: Yup.boolean(),
        danceAddon_hiphop: Yup.boolean(),
        danceAddon_contemporary: Yup.boolean(),
        danceAddon_creativeChildrendance: Yup.boolean(),
        danceAddon_linedance: Yup.boolean(),
        danceAddon_pairdance: Yup.boolean(),
        danceAddon_tapdance: Yup.boolean(),
        tableTennis: Yup.boolean(),
        theatre: Yup.boolean(),
        volleyball: Yup.boolean(),
        adyton: Yup.boolean(),
        adytonAddon: Yup.mixed<'bronze' | 'silber' | 'gold'>().oneOf(['bronze', 'silber', 'gold']),
        tennis: Yup.boolean(),
        tennisAddonChild: Yup.mixed<'none' | 'schnupperWinter' | 'schnupperSommer'>().oneOf([
            'none',
            'schnupperWinter',
            'schnupperSommer',
        ]),
        tennisAddonAdult: Yup.mixed<'none' | 'wellness' | 'schnupperSommer'>().oneOf([
            'none',
            'wellness',
            'schnupperSommer',
        ]),
        freeAthletics: Yup.boolean(),
        aikido: Yup.boolean(),
        badminton: Yup.boolean(),
        kungfu: Yup.boolean(),
        lungSport: Yup.boolean(),
        krebsSport: Yup.boolean(),
        osteoporose: Yup.boolean(),
        backFit: Yup.boolean(),
        trampoline: Yup.boolean(),
        trampolineAddon: Yup.mixed<'erstesKind' | 'weiteresKind'>().oneOf(['erstesKind', 'weiteresKind']),
        sportgymnastik: Yup.boolean(),
        yoga: Yup.boolean(),
        childAthletics: Yup.boolean(),
        onewheelAddons: Yup.object().shape({
            onewheel: Yup.boolean(),
            hockey: Yup.boolean(),
        }),
    })
        .test('isAnySportsChecked', 'Mindestens eine Sportart muss ausgewählt werden', (value) => {
            return Object.values(value || {}).some(
                (val) =>
                    (typeof val === 'boolean' && val === true) ||
                    (typeof val === 'object' && Object.values(val).some((innerVal) => innerVal === true)),
            )
        })
        .required(' '),
}

export default ValidationSchemaSportData
