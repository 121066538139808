import { SubmitButton, useIsMounted } from 'common'
import FormAdditionalData from 'components/FormAdditionalData'
import { AdditionalDataType } from 'components/FormAdditionalData/AdditionalData'
import InitialValuesAdditionalData from 'components/FormAdditionalData/InitialValuesAdditionalData'
import ValidationSchemaAdditionalData from 'components/FormAdditionalData/ValidationSchemaAdditionalData'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import FormPaymentData from 'shared_components/FormPaymentData'
import InitialValuesPaymentData from 'shared_components/FormPaymentData/InitialValuesPaymentData'
import { PaymentDataType } from 'shared_components/FormPaymentData/PaymentDataType'
import ValidationSchemaPaymentData from 'shared_components/FormPaymentData/ValidationSchemaPaymentData'
import FormPersonalData, {
    InitialValuesPersonalData,
    PersonalDataType,
    ValidationSchemaPersonalData,
} from 'shared_components/FormPersonalData'
import FormSportData, {
    InitialValuesSportData,
    SportDataType,
    ValidationSchemaSportData,
} from 'shared_components/FormSportData'
import styles from 'shared_components/forms.module.scss'
import * as Yup from 'yup'

export type RegistrationData = PersonalDataType & SportDataType & AdditionalDataType & PaymentDataType

interface Props {
    onCreate: (data: RegistrationData) => Promise<void>
}

const FormikBuilder = ({ onCreate }: Props): JSX.Element => {
    const isMounted = useIsMounted()

    const validationSchema = Yup.object()
        .shape(ValidationSchemaPersonalData)
        .shape(ValidationSchemaSportData)
        .shape(ValidationSchemaPaymentData)
        .shape(ValidationSchemaAdditionalData)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...InitialValuesPersonalData,
                ...InitialValuesPaymentData,
                ...InitialValuesSportData,
                ...InitialValuesAdditionalData,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }): Promise<void> => {
                setSubmitting(true)

                await onCreate(values)

                if (isMounted()) {
                    setSubmitting(false)
                }
            }}
        >
            {({ values, handleSubmit, handleReset, isSubmitting, errors }): JSX.Element => (
                <Form onSubmit={handleSubmit} onReset={handleReset} data-testid="form" className={styles.form}>
                    <FormPersonalData values={values} />
                    <FormPaymentData values={values} />
                    {values.personalData.membershipType === 'active' && (
                        <FormSportData
                            values={values}
                            dateOfBirth={values.personalData.dateOfBirth}
                            today={new Date()}
                            sportData={InitialValuesSportData}
                            errors={errors?.sportData}
                        />
                    )}
                    <FormAdditionalData values={values} />
                    <SubmitButton disabled={false} isSubmitting={isSubmitting}>
                        Beitragspflichtiges Mitglied werden
                    </SubmitButton>
                </Form>
            )}
        </Formik>
    )
}

export default FormikBuilder
