import { RegistrationData } from 'components/FormikBuilder/FormikBuilder'
import { v4 as uuidv4 } from 'uuid'
import * as convert from 'xml-js'

const options = {
    compact: true,
    ignoreComment: true,
    ignoreDeclaration: false,
    spaces: 4,
    attributesKey: '@',
}

const EMPTY_FAMILY_BATCH_ID = ''
export const DEV_ENV_HOSTNAME = 'online-anmeldung.dev.niendorfertsv.project.konzentrik.de'
const developmentEnvironmentNames = [DEV_ENV_HOSTNAME, 'localhost']

const getName = (value: string): string => {
    if (developmentEnvironmentNames.includes(window.location.hostname)) {
        return `Test${value}`
    }

    return value
}

const getEmail = (value: string, firstName: string, lastName: string): string => {
    if (developmentEnvironmentNames.includes(window.location.hostname)) {
        return `ntsv+${encodeURI(firstName.toLowerCase())}-${encodeURI(lastName.toLowerCase())}@konzentrik.de`
    }

    return value
}

const buildMainRegistration = (data: RegistrationData, familyBatchId: string): Record<string, unknown> => {
    return {
        AnzahlFamMitglieder: familyBatchId ? '2' : '0',
        Mitgliedsnr: data.personalData.memberId,
        FamilyBatchID: familyBatchId,
        // not to be changed by input
        AeltestesMitglied: 'false',
        // not to be changed by input
        Fremdzahler: 'false',
        // not to be changed by input
        AlsPaar: 'false',
        // not to be changed by input
        ErwachsenerMitKind: 'false',
        // not to be changed by input
        ZweiErwachsenePlusKind: 'false',
        SpartenErweiterung: 'false',
        Zahlungsart: 'Einzug',
        Titel: data.personalData.title,
        Name: getName(data.personalData.lastName),
        Vorname: data.personalData.firstName,
        Email: getEmail(data.personalData.email, data.personalData.firstName, data.personalData.lastName),
        Geburtstag: data.personalData.dateOfBirth, // YYYY-MM-DD
        Geschlecht: data.personalData.gender,
        Strasse: data.personalData.street,
        Hausnummer: data.personalData.streetNumber,
        Co: data.personalData.co,
        Plz: data.personalData.zip,
        Ort: data.personalData.city,
        Telefon: data.personalData.phone,
        Iban: data.paymentData.iban,
        Bic: data.paymentData.bic,
        ZahlerName: data.paymentData.hasDifferentAccountOwner ? data.paymentData.lastName : {},
        ZahlerVorname: data.paymentData.hasDifferentAccountOwner ? data.paymentData.firstName : {},
        ZahlerEmail: getEmail(data.personalData.email, data.personalData.firstName, data.personalData.lastName),
        ZahlerGeschlecht: data.personalData.gender,
        ZahlerStrasse: data.personalData.street,
        ZahlerHausnummer: data.personalData.streetNumber,
        ZahlerCo: {},
        ZahlerPlz: data.personalData.zip,
        ZahlerOrt: data.personalData.city,
        ...buildSparten(data),
        Passiv: data.personalData.membershipType === 'active' ? 'false' : 'true',
        // not to be changed by input
        Ermaessigung: 'Keine',
        DigitaleVereinszeitung: 'false',
        // not to be changed by input
        KidsInDieClubs: 'false',
    }
}

const buildAttendeeRegistration = (data: RegistrationData, familyBatchId: string): Record<string, unknown> => {
    const attendee =
        data.sportData.attendee === 'new'
            ? {
                  Titel: '',
                  Name: getName(data.sportData.attendeeNew.lastName),
                  Vorname: data.sportData.attendeeNew.firstName,
                  Email: getEmail(
                      data.sportData.attendeeNew.email,
                      data.sportData.attendeeNew.firstName,
                      data.sportData.attendeeNew.lastName,
                  ),
                  Geburtstag: data.sportData.attendeeNew.dateOfBirth, // YYYY-MM-DD
                  Geschlecht: 'unbekannt',
                  Strasse: data.sportData.attendeeNew.street,
                  Hausnummer: data.sportData.attendeeNew.streetNumber,
                  Co: '',
                  Plz: data.sportData.attendeeNew.zip,
                  Ort: data.sportData.attendeeNew.city,
                  Telefon: '',
              }
            : {
                  Titel: '',
                  Name: getName(data.sportData.attendeeMember.lastName),
                  Vorname: data.sportData.attendeeMember.firstName,
                  Email: '',
                  Geburtstag: data.sportData.attendeeMember.dateOfBirth, // YYYY-MM-DD
                  Geschlecht: 'unbekannt',
                  Strasse: '',
                  Hausnummer: '',
                  Co: '',
                  Plz: '',
                  Ort: '',
                  Telefon: '',
              }

    return {
        AnzahlFamMitglieder: '2',
        Mitgliedsnr: data.sportData.attendee === 'new' ? '' : data.sportData.attendeeMember.memberId,
        FamilyBatchID: familyBatchId,
        // not to be changed by input
        AeltestesMitglied: 'false',
        // not to be changed by input
        Fremdzahler: 'false',
        // not to be changed by input
        AlsPaar: 'false',
        // not to be changed by input
        ErwachsenerMitKind: 'false',
        // not to be changed by input
        ZweiErwachsenePlusKind: 'false',
        SpartenErweiterung: 'false',
        Zahlungsart: 'Einzug',
        ...attendee,
        Iban: data.paymentData.iban,
        Bic: data.paymentData.bic,
        ZahlerName: data.paymentData.hasDifferentAccountOwner ? data.paymentData.lastName : {},
        ZahlerVorname: data.paymentData.hasDifferentAccountOwner ? data.paymentData.firstName : {},
        ZahlerEmail: {},
        ZahlerGeschlecht: {},
        ZahlerStrasse: data.sportData.attendee === 'new' ? data.sportData.attendeeNew.street : data.personalData.street,
        ZahlerHausnummer:
            data.sportData.attendee === 'new'
                ? data.sportData.attendeeNew.streetNumber
                : data.personalData.streetNumber,
        ZahlerCo: {},
        ZahlerPlz: data.sportData.attendee === 'new' ? data.sportData.attendeeNew.zip : data.personalData.zip,
        ZahlerOrt: data.sportData.attendee === 'new' ? data.sportData.attendeeNew.city : data.personalData.city,
        Sparte: [{ '@': { SpartenZusatz: 'Eltern-Kind-Turnen (Erw)' }, _text: 'Turnen, Freizeit, Gesundheitssport' }],
        Passiv: 'false',
        // not to be changed by input
        Ermaessigung: 'Keine',
        DigitaleVereinszeitung: 'false',
        // not to be changed by input
        KidsInDieClubs: 'false',
    }
}

const buildSparten = (data: RegistrationData): Record<string, unknown> => {
    const result: Array<unknown> = []

    // Example: Sparte with SpartenZusatz
    // target: <Sparte SpartenZusatz="Silber">Adyton</Sparte>
    // syntax: { '@': { SpartenZusatz: 'silber' }, _text: 'Adyton' },

    // Example: Sparte
    // target: <Sparte>Basketball</Sparte>
    // syntax: { _text: 'Basketball' },

    if (data.sportData.adyton) {
        if (data.sportData.adytonAddon === 'bronze') {
            result.push({ '@': { SpartenZusatz: 'Bronze' }, _text: 'Adyton' })
        }

        if (data.sportData.adytonAddon === 'silber') {
            result.push({ '@': { SpartenZusatz: 'Silber' }, _text: 'Adyton' })
        }

        if (data.sportData.adytonAddon === 'gold') {
            result.push({ '@': { SpartenZusatz: 'Gold' }, _text: 'Adyton' })
        }
    }

    if (data.sportData.aikido) {
        result.push({ _text: 'Aikido' })
    }

    if (data.sportData.athleticsAddons && data.sportData.athleticsAddons.athletics) {
        result.push({ _text: 'Leichtathletik' })
    }

    if (data.sportData.athleticsAddons && data.sportData.athleticsAddons.walking) {
        result.push({ _text: 'Le - Walken' })
    }

    if (data.sportData.backFit) {
        result.push({ '@': { SpartenZusatz: 'Rückenfit' }, _text: 'Turnen, Freizeit, Gesundheitssport' })
    }

    if (data.sportData.badminton) {
        result.push({ _text: 'Badminton' })
    }

    if (data.sportData.baseball) {
        result.push({ _text: 'Baseball' })
    }

    if (data.sportData.basketball) {
        result.push({ _text: 'Basketball' })
    }

    if (data.sportData.chess) {
        result.push({ _text: 'Schach' })
    }

    if (data.sportData.childAthletics) {
        result.push({
            '@': { SpartenZusatz: 'Eltern-Kind-Turnen (Kind)' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (data.sportData && data.sportData.danceAddon_ballett) {
        result.push({ '@': { SpartenZusatz: 'Ballett' }, _text: 'Tanz' })
    }

    if (data.sportData && data.sportData.danceAddon_bellydance) {
        result.push({ '@': { SpartenZusatz: 'Bauchtanz' }, _text: 'Tanz' })
    }

    if (data.sportData && data.sportData.danceAddon_breakdance) {
        result.push({ '@': { SpartenZusatz: 'Breakdance' }, _text: 'Tanz' })
    }

    if (data.sportData && data.sportData.danceAddon_hiphop) {
        result.push({ '@': { SpartenZusatz: 'Hip Hop' }, _text: 'Tanz' })
    }

    if (data.sportData && data.sportData.danceAddon_contemporary) {
        result.push({ '@': { SpartenZusatz: 'Contemporary' }, _text: 'Tanz' })
    }

    if (data.sportData && data.sportData.danceAddon_creativeChildrendance) {
        result.push({ '@': { SpartenZusatz: 'Kreativer Kindertanz' }, _text: 'Tanz' })
    }

    if (data.sportData && data.sportData.danceAddon_linedance) {
        result.push({ '@': { SpartenZusatz: 'Line Dance' }, _text: 'Tanzsport' })
    }

    if (data.sportData && data.sportData.danceAddon_pairdance) {
        result.push({ '@': { SpartenZusatz: 'Paartanz' }, _text: 'Tanzsport' })
    }

    if (data.sportData && data.sportData.danceAddon_tapdance) {
        result.push({ '@': { SpartenZusatz: 'Stepptanz' }, _text: 'Tanz' })
    }

    if (data.sportData.freeAthletics) {
        result.push({ _text: 'Turnen, Freizeit, Gesundheitssport' })
    }

    if (data.sportData.handball) {
        result.push({ _text: 'Handball' })
    }

    if (data.sportData.judo) {
        result.push({ _text: 'Judo' })
    }

    if (data.sportData.jujutsu) {
        result.push({ _text: 'JuJutsu' })
    }

    if (data.sportData.karate) {
        result.push({ _text: 'Karate' })
    }

    if (data.sportData.kungfu) {
        result.push({ _text: 'Kung Fu' })
    }

    if (data.sportData.lungSport) {
        result.push({
            '@': { SpartenZusatz: 'Lungensport ohne Verordnung' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (data.sportData.krebsSport) {
        result.push({
            '@': { SpartenZusatz: 'Krebsnachsorge ohne Verordnung' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (data.sportData.onewheelAddons && data.sportData.onewheelAddons.hockey) {
        result.push({ '@': { SpartenZusatz: 'Einradhockey' }, _text: 'Einrad' })
    }

    if (data.sportData.onewheelAddons && data.sportData.onewheelAddons.onewheel) {
        result.push({ '@': { SpartenZusatz: 'Einrad' }, _text: 'Einrad' })
    }

    if (data.sportData.osteoporose) {
        result.push({
            '@': { SpartenZusatz: 'Osteoporose' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (data.sportData.soccer) {
        result.push({ _text: 'Fußball' })
    }

    if (data.sportData.swimming) {
        result.push({ _text: 'Schwimmen' })
    }

    if (data.sportData.tableTennis) {
        result.push({ _text: 'Tischtennis' })
    }

    if (data.sportData.tennis) {
        if (data.sportData.tennisAddonAdult === 'none' && data.sportData.tennisAddonChild === 'none') {
            result.push({ _text: 'Tennis' })
        }

        if (
            data.sportData.tennisAddonAdult === 'schnupperSommer' ||
            data.sportData.tennisAddonChild === 'schnupperSommer'
        ) {
            result.push({ _text: 'TennisSchnupperSommer' })
        }

        if (data.sportData.tennisAddonChild === 'schnupperWinter') {
            result.push({ _text: 'TennisSchnupperWinter' })
        }

        if (data.sportData.tennisAddonAdult === 'wellness') {
            result.push({ _text: 'Tennis' })
            result.push({ '@': { SpartenZusatz: 'Bronze' }, _text: 'Adyton' })
        }
    }

    if (data.sportData.theatre) {
        result.push({ _text: 'Theater/Laienspiel' })
    }

    if (data.sportData.trampoline) {
        result.push({
            '@': { SpartenZusatz: 'Trampolinturnen' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (data.sportData.sportgymnastik) {
        result.push({ Name: { _text: 'rhythmische Sportgymnastik' } })
    }

    if (data.sportData.volleyball) {
        result.push({ _text: 'Volleyball' })
    }

    if (data.sportData.yoga) {
        result.push({
            '@': { SpartenZusatz: 'Yoga' },
            _text: 'Turnen, Freizeit, Gesundheitssport',
        })
    }

    if (result.length === 0) {
        result.push({ _text: 'Spartenfrei' })
    }

    return { Sparte: result }
}

const getAnmeldungEntries = (data: RegistrationData): Array<unknown> => {
    if (data.sportData.childAthletics) {
        const familyBatchId = uuidv4()
        return [buildMainRegistration(data, familyBatchId), buildAttendeeRegistration(data, familyBatchId)]
    }

    return [buildMainRegistration(data, EMPTY_FAMILY_BATCH_ID)]
}

const transformToXml = (data: RegistrationData): string => {
    return convert.js2xml(
        {
            _declaration: {
                // @ts-ignore
                '@': {
                    version: '1.0',
                    encoding: 'utf-8',
                },
            },
            OnlineAnmeldung: {
                '@': {
                    'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
                },
                Anmeldung: getAnmeldungEntries(data),
            },
        },
        options,
    )
}

export default transformToXml
